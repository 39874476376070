<style scoped>
	.outer {
		width: 100%;
		background: #393B3D;
		min-width: 1320px;
	}

	.container {
		height: 345px;
		flex-direction: column;
		margin: auto;
		padding: 0 20px;
	}

	.box1 {
		width: 1200px;
		color: #fff;
		font-size: 18px;
		position: relative;
	}

	.box1::after {
		content: "";
		position: absolute;
		bottom: -47px;
		left: 0;
		height: 1px;
		width: 100%;
		background: #8F9091;
	}

	.box1>img {
		height: 56px;
		width: 155px;
	}

	.box1-inner1 {
		margin: 0 30px;
	}

	.box2 {
		height: 122px;
	}

	.box2-left-top>div:last-child {
		border-right: none;
	}

	.box2-left {
		flex-direction: column;
		margin-right: 100px;
	}

	.box2-item {
		width: 6em;
		color: #fff;
		border-right: 1px solid #fff;
		margin-right: 40px;
	}

	.box2-left-bottom {
		color: #fff;
	}

	.box2-right {
		color: #fff;
		align-items: flex-end;
	}

	.box2Left img {
		width: 12px;
		height: 12px;
	}

	.boxRight img {
		width: 122px;
		height: 122px;
		margin-left: 30px;
	}

	.box2-innerbox {
		margin-top: 20px;
		width: 125px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		border: 1px solid #D7D8D8;
		border-radius: 5px;
	}
</style>

<template>
	<div class="outer" :style="{'min-width': minWidth + 'px'}">
		<div class="container flex-around flex-y-center" :style="{'min-width': minWidth + 'px'}">
			<div class="box1 flex-y-center">
				<img :src="info.logo" alt="">
				<div class="box1-inner1">
					<p v-if="info.mobile">联系电话：{{info.mobile}}</p>
					<p v-if="info.email">邮箱：{{info.email}}</p>
				</div>
				<div>
					<p v-if="info.qq">QQ：{{info.qq}}</p>
					<p v-if="info.address">地址：{{info.address}}</p>
				</div>
			</div>
			<div class="box2 flex">
				<div class="box2-left flex-between">
					<div class="box2-left-top flex">
						<div class="box2-item">网站首页</div>
						<div class="box2-item">企业介绍</div>
						<div class="box2-item">产品服务</div>
						<div class="box2-item">合作商家</div>
						<div class="box2-item">咨询动态</div>
						<div class="box2-item">联系我们</div>
					</div>
					<div class="box2-left-bottom flex">
						<p style="margin-right: 178px;">copyright {{info.name ? info.name : "深圳佑护科技有限公司"}} 2017-2020</p>
						<p v-if="info.icp">备案号：<a style="color:#fff" href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">{{info.icp}}</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info: JSON.parse(sessionStorage.getItem("topBottomInfo"))
			}
		},
		props: {
			minWidth: {
				type: Number
			}
		}
	}
</script>
